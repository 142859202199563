<template>
  <!--  资产对象下的二维码、条形码弹窗-->
  <div>
    <!--    展示单个码-->
    <el-dialog
      :visible.sync="dialogVisibleSingle"
      width="30%"
      :before-close="handleClose"
    >
      <span slot="title" class="code-show-title">
        {{ $t("label.preview.print.template") }}
        <!-- 预览打印模板 -->
      </span>
      <div class="code-template-show">
        <!-- 条形码-->
        <div v-if="codeType === 'barcode'" class="bar-code-item">
          <div class="bar-code-item-box">
            <div class="bar-code-item-name">
              <!-- 产品名称 -->
              {{ $t("label.product.name") }}
            </div>
            <div>
              <svg class="bar-code-item-box-img" aria-hidden="true">
                <use href="#icon-barcode-demo"></use>
              </svg>
            </div>
            <div class="bar-code-item-order">2018041012345678</div>
            <div class="bar-code-item-type">
              {{ $t("label.model.un") }}
              <!-- 型号：UN6786 -->
            </div>
          </div>
        </div>
        <!--        二维码-->
        <div v-if="codeType === 'qrcode'" class="qr-code-item">
          <div class="qr-code-item-box">
            <div>
              <svg class="qr-code-item-box-img" aria-hidden="true">
                <use href="#icon-qrcode-demo"></use>
              </svg>
            </div>
            <div class="qr-code-item-desc">
              <div class="qr-code-item-desc-name">
                <!-- 产品名称 -->
                {{ $t("label.product.name") }}
              </div>
              <div class="qr-code-item-desc-type">
                {{ $t("label.model.un") }}
                <!-- 型号：UN6786 -->
              </div>
              <div class="qr-code-item-desc-order">2018041012345678</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-style" @click="closeDialog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="downloadCode(codeType, 'print')"
          class="btn-style-down"
        >
          <!-- 打印 -->
          {{ $t("label.print") }}
        </el-button>
        <el-button
          v-if="showDownloadBtn"
          @click="downloadCode(codeType, 'download')"
          class="btn-style-down"
        >
          {{ $t("label.download.save.attachment") }}
          <!-- 下载并保存至附件 -->
        </el-button>
      </span>
    </el-dialog>
    <!--    同时展示-->
    <el-dialog
      :visible.sync="dialogVisibleAll"
      width="60%"
      :before-close="handleClose"
    >
      <span slot="title" class="code-show-title">
        {{ $t("label.preview.print.template") }}
        <!-- 预览打印模板 -->
      </span>
      <div class="code-template-show">
        <!--        条形码-->
        <div class="code-item">
          <div class="code-item-bar-box">
            <div class="code-item-bar-name">
              <!-- 产品名称 -->
              {{ $t("label.product.name") }}
            </div>
            <div>
              <svg class="code-item-bar-box-img" aria-hidden="true">
                <use href="#icon-barcode-demo"></use>
              </svg>
            </div>
            <div class="code-item-bar-type">
              {{ $t("label.model.un") }}
              <!-- 型号：UN6786 -->
            </div>
            <div class="code-item-bar-order">2018041012345678</div>
          </div>
          <div class="code-item-tip">
            {{ $t("label.enable.barcode.only") }}
            <!-- 仅启用条形码 -->
          </div>
          <div class="code-item-btn-box">
            <el-button
              type="primary"
              @click="downloadCode('barcode', 'print')"
              class="btn-style-down"
            >
              <!-- 打印 -->
              {{ $t("label.print") }}
            </el-button>
            <el-button
              type="primary"
              v-if="showDownloadBtn"
              @click="downloadCode('barcode', 'download')"
              class="btn-style-down"
            >
              {{ $t("label.download.save.attachment") }}
              <!-- 下载并保存至附件 -->
            </el-button>
          </div>
        </div>
        <!--        二维码-->
        <div class="code-item">
          <div class="code-item-qr-box">
            <div>
              <svg class="code-item-qr-box-img" aria-hidden="true">
                <use href="#icon-qrcode-demo"></use>
              </svg>
            </div>
            <div class="code-item-desc">
              <div class="code-item-desc-name">
                <!-- 产品名称 -->
                {{ $t("label.product.name") }}
              </div>
              <div class="code-item-desc-type">
                {{ $t("label.model.un") }}
                <!-- 型号：UN6786 -->
              </div>
              <div class="code-item-desc-order">2018041012345678</div>
            </div>
          </div>
          <div class="code-item-tip">
            {{ $t("label.enable.qrcode.only") }}
            <!-- 仅启用二维码 -->
          </div>
          <div class="code-item-btn-box">
            <el-button
              type="primary"
              @click="downloadCode('qrcode', 'print')"
              class="btn-style-down"
            >
              <!-- 打印 -->
              {{ $t("label.print") }}
            </el-button>
            <el-button
              type="primary"
              v-if="showDownloadBtn"
              @click="downloadCode('qrcode', 'download')"
              class="btn-style-down"
            >
              {{ $t("label.download.save.attachment") }}
              <!-- 下载并保存至附件 -->
            </el-button>
          </div>
        </div>

        <div class="code-item">
          <div class="code-item-both-box-p">
            <div class="code-item-both-box">
              <div class="code-item-both-name">
                <!-- 产品名称 -->
                {{ $t("label.product.name") }}
              </div>
              <div>
                <svg class="code-item-both-qr" aria-hidden="true">
                  <use href="#icon-qrcode-demo"></use>
                </svg>
              </div>
              <div class="code-item-both-type">
                {{ $t("label.model.un") }}
                <!-- 型号：UN6786 -->
              </div>
              <div>
                <svg class="code-item-both-bar" aria-hidden="true">
                  <use href="#icon-barcode-demo"></use>
                </svg>
              </div>
              <div class="code-item-both-order">2018041012345678</div>
            </div>
          </div>
          <div class="code-item-tip code-item-tip-end">
            {{ $t("label.enable.both.barcode.qrcode") }}
            <!-- 同时启用条形码+二维码 -->
          </div>
          <div class="code-item-btn-box">
            <el-button
              type="primary"
              @click="downloadCode('double', 'print')"
              class="btn-style-down"
            >
              <!-- 打印 -->
              {{ $t("label.print") }}
            </el-button>
            <el-button
              type="primary"
              v-if="showDownloadBtn"
              @click="downloadCode('double', 'download')"
              class="btn-style-down"
            >
              {{ $t("label.download.save.attachment") }}
              <!-- 下载并保存至附件 -->
            </el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-style" @click="closeDialog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

axios.defaults.timeout = 50000;
axios.defaults.baseURL = window.Glod["ccex-apitsf"] + "/api";

export default {
  name: "CodeDialog",
  props: {},
  data() {
    return {
      idList: "",
      dialogVisibleSingle: false,
      showDownloadBtn: true,
      dialogVisibleAll: false,
      objId: "asset",
      codeType: "",
      printNameObj: {}, // 打印展示的名称
    };
  },
  methods: {
    handleClose() {
      this.dialogVisibleSingle = false;
      this.dialogVisibleAll = false;
    },
    closeDialog() {
      this.dialogVisibleSingle = false;
      this.dialogVisibleAll = false;
    },
    downloadCode(flag, operate) {
      // ids	String	对象ids,多个用，分割
      // codeType	String	码类型
      // operation	String	（打印保存附件传download，传其他只打印）
      // objId	String	对象Id
      let option = {
        ids: this.idList,
        codeType: flag,
        operation: operate,
        objId: this.objId,
      };
      axios
        .post(`assetCode/printCode`, option, {
          headers: {
            accesstoken: this.$CCDK.CCToken.getToken(),
          },
          responseType: "arraybuffer",
          // responseType: 'blob',
        })
        .then((response) => {
          if (operate === "print") {
            const binaryData = [];
            binaryData.push(response.data);
            //获取blob链接
            this.pdfUrl = window.URL.createObjectURL(
              new Blob(binaryData, { type: "application/pdf" })
            );
            window.open(this.pdfUrl);
          } else if (operate === "download") {
            this.pdfUrl = window.URL.createObjectURL(
              new Blob([response.data], { type: `application/pdf` })
            );
            const fname = this.printNameObj[flag]; // 下载文件的名字
            const link = document.createElement("a");
            link.href = this.pdfUrl;
            link.setAttribute("download", fname);
            document.body.appendChild(link);
            link.click();
          }
        });
    },
    // openDialog(flag, type) {
    //   // flag为0 条形码
    //   // flag为1 二维码
    //   // flag为2 二维码和条形码
    //   if (type === "table") {
    //     this.showDownloadBtn = true;
    //   } else {
    //     this.showDownloadBtn = true;
    //   }
    //   this.idList = "";
    //   this.idList = flag.toString();
    //   assetBOMApiApi
    //     .checkPrintCode({
    //       objId: this.objId,
    //       id: this.idList,
    //     })
    //     .then((res) => {
    //       if (res.result) {
    //         if (res.data.code === 400) {
    //           let showTip = this.$i18n.t("label.insert.field.empty.continue", {
    //             resDataField: res.data.field,
    //           });
    //           // "存在【" +
    //           // res.data.field +
    //           // "】值为空的记录无法生成码,是否仍要继续生成?";
    //           MessageBox.confirm(showTip, {
    //             confirmButtonText: this.$i18n.t("label.goon"), //继续 this.$i18n.t("label.goon")
    //             cancelButtonText: i18n.t("label_chatter_cancel"),
    //             type: "warning",
    //           })
    //             .then(() => {
    //               this.getRules();
    //             })
    //             .catch(() => {});
    //         } else if (res.data.code === 200) {
    //           this.getRules();
    //         }
    //       }
    //     });
    // },
    // getRules() {
    //   assetBOMApiApi
    //     .getEnableRules({
    //       objId: this.objId,
    //     })
    //     .then((res) => {
    //       if (res.result) {
    //         this.codeType = res.data.rule;
    //         if (this.codeType === "double") {
    //           this.dialogVisibleSingle = false;
    //           this.dialogVisibleAll = true;
    //           this.printNameObj = {
    //             qrcode: res.data.qrRuleName || "",
    //             barcode: res.data.barRuleName || "",
    //             double: res.data.doubleRuleName || "",
    //           };
    //         } else if (this.codeType && this.codeType !== "double") {
    //           this.dialogVisibleAll = false;
    //           this.dialogVisibleSingle = true;
    //           this.printNameObj = {
    //             qrcode: res.data.ruleName || res.data.qrRuleName || "",
    //             barcode: res.data.ruleName || res.data.barRuleName || "",
    //             double: res.data.ruleName || res.data.doubleRuleName || "",
    //           };
    //         } else {
    //           this.$message.warning(
    //             this.$i18n.t("label.please.contact.your.administrator")
    //             // "请联系管理员，启用生成二维码/条形码的规则"
    //           );
    //         }
    //       }
    //     })
    // },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped>
.code-show-title {
  height: 21px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  color: #464445;
  opacity: 1;
  display: flex;
}
.code-template-show {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 16px 0;
  box-sizing: border-box;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;

  .code-item {
    min-width: 220px;
    height: 186px;
    //border: 1px solid #42b983;
    margin-bottom: 5px;
    &-bar-box {
      height: 120px;
      padding: 13px 30px;
      background-color: #fff;
      margin-bottom: 10px;
      box-sizing: border-box;
      .code-item-bar-box-img {
        width: 160px;
        height: 26px;
        margin-bottom: 6px;
        //background: #000;
      }
      .code-item-bar-name {
        font-size: 16px;
        font-weight: bold;
        line-height: 21px;
        color: #464445;
        margin-bottom: 4px;
      }
      .code-item-bar-order {
        font-size: 10px;
        font-weight: bold;
        line-height: 14px;
        color: #464445;
      }
      .code-item-bar-type {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        color: #464445;
        margin-bottom: 6px;
      }
    }
    &-qr-box {
      display: flex;
      padding: 25px 18px;
      background-color: #fff;
      margin-bottom: 10px;
      &-img {
        width: 70px;
        height: 70px;
        margin-right: 15px;
        //background: #000;
      }
    }
    .code-item-both-box-p {
      height: 120px;
      width: 220px;
    }
    &-both-box {
      width: 120px;
      transform: rotate(-90deg) translate(50px, 50px);
      height: 220px;
      background-color: #fff;
      margin-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      .code-item-both-name {
        font-size: 16px;
        font-weight: bold;
        line-height: 21px;
        color: #464445;
        margin-bottom: 11px;
      }
      .code-item-both-qr {
        width: 70px;
        height: 70px;
        margin-bottom: 12px;
        //background: #000;
      }
      .code-item-both-type {
        font-size: 10px;
        font-weight: bold;
        line-height: 14px;
        color: #464445;
        margin-bottom: 10px;
      }
      .code-item-both-bar {
        width: 105px;
        height: 26px;
        margin-bottom: 6px;
        //background: #000;
      }
      .code-item-both-order {
        font-size: 8px;
        font-weight: bold;
        line-height: 10px;
        color: #464445;
      }
    }
    .code-item-tip {
      text-align: left;
      //height: 16px;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      color: #464445;
      opacity: 1;
      margin-bottom: 10px;
    }
    .code-item-tip-end {
      padding-top: 10px;
    }
    .code-item-btn-box {
      display: flex;
      justify-content: center;
      .code-item-btn {
        min-width: 60px;
        height: 30px;
        font-size: 14px;
        line-height: 19px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #464445;
      opacity: 1;
      &-name {
        height: 21px;
        font-size: 16px;
        font-weight: bold;
        line-height: 21px;
        margin-bottom: 10px;
      }
      &-type {
        height: 16px;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 9px;
      }
      &-order {
        height: 14px;
        font-size: 10px;
        font-weight: bold;
        opacity: 1;
      }
    }
  }
  .bar-code-item {
    width: 295px;
    height: 165px;
    background: #ffffff;
    .bar-code-item-box {
      padding: 13px 30px;
      margin-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .bar-code-item-name {
        margin-bottom: 15px;
        height: 24px;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #464445;
      }
      .bar-code-item-box-img {
        height: 46px;
        margin-bottom: 8px;
        //background: #000;
      }
      .bar-code-item-order {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #464445;
        margin-bottom: 8px;
      }
      .bar-code-item-type {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #464445;
        opacity: 1;
      }
    }
  }
  .qr-code-item {
    width: 295px;
    height: 165px;
    background: #ffffff;
    .qr-code-item-box {
      padding: 37px 20px;
      margin-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      .qr-code-item-box-img {
        width: 90px;
        height: 90px;
        margin-right: 15px;
        //background: #000;
      }
      .qr-code-item-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #464445;
        opacity: 1;
        &-name {
          height: 24px;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          margin-bottom: 12px;
        }
        &-type {
          height: 24px;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          margin-bottom: 11px;
        }
        &-order {
          height: 19px;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  .btn-style {
    background: #fff;
    width: 60px;
    height: 30px;
    font-size: 14px;
    color: #080707;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-style-down {
    background: #005fb2;
    color: #f8f8f8;
    //width: 60px;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__body,
::v-deep .el-dialog__header,
::v-deep .el-dialog__footer {
  background-color: #f8f8f8;
}
</style>
